import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RemoteTable from "../../components/ui/RemoteTable";
import http from "../../services/httpServices";
import InputField from "../../components/form/elements/InputField";
import { handleInputChange } from "../../services/formServices";
import AdminContainer from "../../components/ui/AdminContainer";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import { printFullDate } from "../../utils/time";

const InsertAutosFollowUp = () => {
    const initialState = {
        government_id: 0,
        center_id: 0,
        phone: "",
        father_phone: "",
        charge_status: "",
        insert_autos_id: 0,
    };
    const [filterData, setFilterData] = useState(initialState);
    const [data, setData] = useState(initialState);
    const [centers, setCenters] = useState([]);
    const [governments, setGovernments] = useState([]);
    const [insertAutos, setInsertAutos] = useState([]);

    async function getCenters() {
        const { data } = await http.get("/api/centers/options");
        setCenters(data);
    }

    async function getInsertAutos() {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data } = await http.get("/api/insert_autos/options", config);
        setInsertAutos(data);
    }

    const getGovernments = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/governments`, config);
        setGovernments(response);
    };

    useEffect(() => {
        getCenters();
        getGovernments();
        getInsertAutos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم الطالب - المٌستهلك",
            reorder: true,
            selector: (row) =>
                row.charged_to_user ? (
                    row.charged_to_user.full_name
                ) : (
                    <span className="text-Orange">لا يوجد مٌستهلك</span>
                ),
        },
        {
            name: "كود الشحن",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    element="Link"
                    to={`../user_profile/${row.id}`}
                >
                    0{row.phone}
                </Link>
            ),
        },
        {
            name: "رقم هاتف الطالب",
            reorder: true,
            selector: (row) =>
                row.charged_to_user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.charged_to_user.id}`}
                    >
                        0{row.charged_to_user.phone}
                    </Link>
                ) : (
                    <span className="text-Orange">لا يوجد مٌستهلك</span>
                ),
        },
        {
            name: "رقم هاتف ولي الأمر",
            reorder: true,
            selector: (row) =>
                row.charged_to_user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.charged_to_user.id}`}
                    >
                        0{row.charged_to_user.father_phone}
                    </Link>
                ) : (
                    <span className="text-Orange">لا يوجد مٌستهلك</span>
                ),
        },
        {
            name: "إيميل الطالب",
            reorder: true,
            selector: (row) =>
                row.charged_to_user ? (
                    row.charged_to_user.email
                ) : (
                    <span className="text-Orange">لا يوجد مٌستهلك</span>
                ),
        },
        {
            name: "اسم السنتر",
            reorder: true,
            selector: (row) =>
                row.center ? (
                    <div className="bg-indigo-500 px-3 py-2 rounded-lg clr-white ">
                        {row.center.name}
                    </div>
                ) : (
                    <div className="text-rose-500">--تم حذف السنتر--</div>
                ),
            sortable: true,
            sortField: "center_id",
        },
        {
            name: "حالة استهلاك الكود",
            reorder: true,
            selector: (row) =>
                !row.charged_to_user_id ? (
                    <span className="text-red-500">غير مستهلك</span>
                ) : (
                    <span className="text-green-900 font-w-bold bg-green-300 py-2 px-4 border border-black rounded-md shadow-md">
                        تم استهلاكه
                    </span>
                ),
        },
        {
            name: "تاريخ شحن الكود",
            reorder: true,
            selector: (row) => (row.updated_at ? printFullDate(row.updated_at) : "--"),
            sortable: true,
            sortField: "updated_at",
        },
    ];
    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="phone"
                        placeholder="كود الشحن"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="charged_to_user_phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="charged_to_user_father_phone"
                        placeholder="رقم هاتف ولي الأمر"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="government_id"
                        type="select"
                        placeholder="اختر المحافظة"
                        options={[
                            {
                                value: 0,
                                text: "كل المحافظات",
                            },
                            ...governments,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="center_id"
                        type="select"
                        placeholder="اختر الموزع المراد البحث عنه"
                        options={[
                            {
                                value: 0,
                                text: "كل الموزعين",
                            },
                            ...centers,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="insert_autos_id"
                        type="select"
                        placeholder="اختر مجموعة الأكواد المراد تتبعها"
                        options={[
                            {
                                value: 0,
                                text: "كل الأكواد",
                            },
                            ...insertAutos,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="charge_status"
                        type="select"
                        placeholder="حالة الشحن والإستهلاك"
                        options={[
                            {
                                value: 0,
                                text: "جميع الحالات",
                            },
                            {
                                value: "pending",
                                text: "غير مستهلك",
                            },
                            {
                                value: "charged",
                                text: "تم استهلاكه",
                            },
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/insert_auto_follow_up/paginate`}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default InsertAutosFollowUp;
