import AddAdminToAdminNotesForm from "../pages/admin/AddAdminToAdminNotesForm";
import AddAdminToAdminTagsForm from "../pages/admin/AddAdminToAdminTagsForm";
import AdminToAdminNotesTableCell from "../pages/admin/AdminToAdminNotesTableCell";
import AdminToAdminTagsTableCell from "../pages/admin/AdminToAdminTagsTableCell";
import auth from "./authServices";
import http from "./httpServices";

export const fetchAdminToAdminUserTags = async () => {
    const token = auth.getAdminToken();
    const config = auth.getAdminAuthConfig(token);

    const { data: response } = await http.get(
        `/api/admin_to_admin_user_tags/for_tables/options`,
        config
    );
    return response;
};

export const handlePostSubmitNewTag = (
    user,
    note,
    editableData,
    setEditableData,
    setIsToEditData,
    isSubRow = false
) => {
    let newData = editableData.map((row) => {
        let admin_to_admin_user_tags = (
            isSubRow ? row.user.admin_to_admin_user_tags : row.admin_to_admin_user_tags
        )
            ? [
                  note,
                  ...(isSubRow ? row.user.admin_to_admin_user_tags : row.admin_to_admin_user_tags),
              ]
            : [note];
        if ((isSubRow ? row.user.id : row.id) === user.id) {
            if (isSubRow) {
                return {
                    ...row,
                    user: {
                        ...row.user,
                        admin_to_admin_user_tags,
                    },
                };
            } else {
                return {
                    ...row,
                    admin_to_admin_user_tags,
                };
            }
        } else {
            return row;
        }
    });
    setEditableData(newData);
    setIsToEditData(true);
};
export const handlePostSubmitNewNote = (
    user,
    note,
    editableData,
    setEditableData,
    setIsToEditData,
    isSubRow = false
) => {
    let newData = editableData.map((row) => {
        let admin_to_admin_user_notes = (
            isSubRow ? row.user.admin_to_admin_user_notes : row.admin_to_admin_user_notes
        )
            ? [
                  note,
                  ...(isSubRow
                      ? row.user.admin_to_admin_user_notes
                      : row.admin_to_admin_user_notes),
              ]
            : [note];
        if ((isSubRow ? row.user.id : row.id) === user.id) {
            if (isSubRow) {
                return {
                    ...row,
                    user: {
                        ...row.user,
                        admin_to_admin_user_notes,
                    },
                };
            } else {
                return {
                    ...row,
                    admin_to_admin_user_notes,
                };
            }
        } else {
            return row;
        }
    });
    setEditableData(newData);
    setIsToEditData(true);
};

export const returnAdminToAdminTagsTableColoumns = (
    adminToAdminUserTags,
    editableData,
    setEditableData,
    setIsToEditData,
    isSubRow = false
) => {
    return [
        {
            name: "اضافة تاجات الأدمن",
            reorder: true,
            selector: (row) => (
                <AddAdminToAdminTagsForm
                    user={isSubRow ? row.user : row}
                    afterSuccess={handlePostSubmitNewTag}
                    onTable={true}
                    parentAdminToAdminUserTags={adminToAdminUserTags}
                    editableData={editableData}
                    setEditableData={setEditableData}
                    setIsToEditData={setIsToEditData}
                    isSubRow={isSubRow}
                />
            ),
            minWidth: "400px",
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        },
        {
            name: "تاجات الأدمن",
            reorder: true,
            selector: (row) => <AdminToAdminTagsTableCell user={isSubRow ? row.user : row} />,
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        },
    ];
};

export const returnAdminToAdminNotesTableColoumns = (
    editableData,
    setEditableData,
    setIsToEditData,
    isSubRow = false
) => {
    return [
        {
            name: "اضافة ملاحظات الادمن",
            reorder: true,
            selector: (row) => (
                <AddAdminToAdminNotesForm
                    user={isSubRow ? row.user : row}
                    afterSuccess={handlePostSubmitNewNote}
                    onTable={true}
                    editableData={editableData}
                    setEditableData={setEditableData}
                    setIsToEditData={setIsToEditData}
                    isSubRow={isSubRow}
                />
            ),
            minWidth: "400px",
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        },
        {
            name: "ملاحظات الأدمن",
            reorder: true,
            selector: (row) => <AdminToAdminNotesTableCell user={isSubRow ? row.user : row} />,
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        },
    ];
};
