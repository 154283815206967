import React, { useContext, useState } from "react";
import ChargeInsertAutoForm from "./ChargeInsertAutoForm";
import InputField from "../../components/form/elements/InputField";
import { handleInputChange } from "../../services/formServices";
import MigrationFromInsertAutoForm from "./MigrationFromInsertAutoForm";
import AuthContext from "../../context/AuthContext";

const ChargeInsertAuto = () => {
    const { user } = useContext(AuthContext);
    const [data, setData] = useState({ is_charge: false });
    return (
        <div className="flex flex-col space-y-8">
            {user.insert_auto_id > 0 ? (
                ""
            ) : (
                <div className="flex-center-both">
                    <InputField
                        placeholder="لو الكود اللي معاك كود حصة / محاضرة اضغط هنا"
                        type="switch"
                        id="is_charge"
                        data={data}
                        setData={setData}
                        onChange={handleInputChange}
                    />
                </div>
            )}

            {user.insert_auto_id > 0 || data.is_charge ? (
                <ChargeInsertAutoForm />
            ) : (
                <MigrationFromInsertAutoForm />
            )}
            {/* <ChargeInsertAutoForm /> */}
            {/* <MigrationFromInsertAutoForm /> */}
        </div>
    );
};

export default ChargeInsertAuto;
