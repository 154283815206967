import React, { useEffect, useState } from "react";
import Release from "../../assets/release.svg";
import "./followuptablecard.css";
import CenterIcon from "./CenterIcon";
import { ReactComponent as SectionHeaderIcon } from "../../assets/parent-dashboard-assets/sectionHeaderDecore.svg";
const FollowUpTableCard = ({
    section = {
        name: "الأسبوع الأول",
        first_upload_date: "2024-02-26 02:10:51",
        follow_up_data: {
            videos: [70, 50],
            exams: [50, 20, 0],
            hms: [50, 40, "absent"],
        },
    },
}) => {
    const [showCardDetails, setShowCardDetails] = useState(false);

    const [isSectionEmpty, setIsSectionEmpty] = useState(false);

    useEffect(() => {
        setIsSectionEmpty(
            !section.follow_up_data.videos.length &&
                !section.follow_up_data.exams.length &&
                !section.follow_up_data.hms.length
        );
    }, [
        section.follow_up_data.videos.length,
        section.follow_up_data.exams.length,
        section.follow_up_data.hms.length,
    ]);
    return (
        <div className="relative">
            <div
                onClick={() => setShowCardDetails(!showCardDetails)}
                className={`w-full z-10 relative flex justify-center items-center px-2 md:px-0  py-5 smooth transform hover:cursor-pointer hover:scale-[100.5%] bg-gray-100 dark:bg-[#2F2A50] shadow-md   clr-text-primary ${
                    showCardDetails && `shadow-md`
                }`}
            >
                <span className=" text-stroke font-forsha text-transparent text-2xl md:text-6xl absolute top-2 md:top-10 left-4 md:left-[15%] ">
                    FULLMARK
                </span>
                <div className="relative w-fit flex h-fit  items-center justify-start md:justify-center ">
                    <h1 className=" md:font-w-bold h-auto flex-center-both text-base md:text-2xl text-center relative z-10 flex gap-2">
                        <div className="relative flex-center-both h-auto py-10 w-[180px] md:w-[450px]">
                            <SectionHeaderIcon
                                className="absolute inset-0 w-full h-full opacity-20 fill-black dark:fill-violet-400 smooth"
                                style={{ objectFit: "contain" }}
                            />
                            <span className="mb-2 z-10">{section.name}</span>
                        </div>

                        {section.first_upload_date ? (
                            <>
                                <span>-</span>
                                <div className="font-small">
                                    <div className="font-smaller -mb-3">بدأ في :</div>
                                    <div className="font-small font-w-bold">
                                        “{section.first_upload_date}”
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </h1>
                </div>
                {isSectionEmpty ? (
                    <div className=" absolute bg-yellow-200 clr-text-primary dark:bg-yellow-800 smooth rounded-xl py-1 px-3 border-black border left-25 bottom-5 md:bottom-16 md:left-10">
                        لم يتم رفع محتوى
                    </div>
                ) : (
                    <div className="h-full flex-center-both">
                        <img
                            src={Release}
                            className={`transform smooth text-rose-500 ${
                                showCardDetails ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                            } absolute left-5 md:left-10 w-6 md:w-8`}
                            alt="releaseDay"
                        />
                        <img
                            src={Release}
                            className={`transform smooth text-rose-500 ${
                                showCardDetails ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                            } absolute right-5 md:right-10 w-6 md:w-8`}
                            alt="releaseDay"
                        />
                    </div>
                )}
            </div>
            {showCardDetails && !isSectionEmpty ? (
                <div
                    className={`fade-down-in h-full py-14 px-5  overflow-x-auto bg-slate-100 dark:bg-slate-800 -mt-7 rounded-xl smooth clr-text-primary`}
                >
                    <table className="table-auto w-full table-study-style rounded-xl">
                        <thead className="py-10 bg-violet-200 dark:bg-violet-800 clr-text-primary smooth">
                            <tr className="">
                                <th className="h-20 text-center clr-text-primary smooth rounded-tr-xl"></th>
                                <th className="h-20 text-center clr-text-primary smooth">
                                    الفيديوهات
                                </th>
                                <th className="h-20 text-center clr-text-primary smooth ">
                                    الواجبات
                                </th>
                                <th className="h-20 text-center clr-text-primary smooth rounded-tl-xl">
                                    الامتحانات
                                </th>
                            </tr>
                        </thead>
                        <tbody className="py-10 bg-violet-50 dark:bg-slate-800 smooth ">
                            <tr className={`${""}`}>
                                <td className="h-20 text-center clr-text-primary smooth">
                                    مستوى الطالب
                                </td>
                                <td className="h-20 text-center space-y-2">
                                    {section.follow_up_data.videos.length > 0
                                        ? section.follow_up_data.videos.map((percentage, index) => (
                                              <div
                                                  className={`border-secondary-container smooth clr-text-primary pb-2 ${
                                                      index + 1 ==
                                                      section.follow_up_data.videos.length
                                                          ? ""
                                                          : "border-b"
                                                  }`}
                                              >
                                                  <span className="font-w-bold">
                                                      {placeholderArray.videos[index]}
                                                  </span>{" "}
                                                  :{" "}
                                                  {percentage !== "absent" ? (
                                                      <>
                                                          تم مشاهدة{" "}
                                                          <span className="font-w-bold">
                                                              {percentage}%
                                                          </span>{" "}
                                                          من الفيديو
                                                      </>
                                                  ) : (
                                                      <span className="text-rose-500">
                                                          لم يتم مشاهدة الفيديو
                                                      </span>
                                                  )}
                                              </div>
                                          ))
                                        : "لا يوجد فيديوهات حتى الآن"}
                                </td>
                                <td className="h-20 text-center space-y-2">
                                    {section.follow_up_data.hms.length > 0
                                        ? section.follow_up_data.hms.map((percentage, index) => (
                                              <div
                                                  className={`border-secondary-container smooth clr-text-primary pb-2 ${
                                                      index + 1 == section.follow_up_data.hms.length
                                                          ? ""
                                                          : "border-b"
                                                  }`}
                                              >
                                                  <span className="font-w-bold">
                                                      {placeholderArray.hms[index]}
                                                  </span>{" "}
                                                  :{" "}
                                                  {percentage !== "absent" ? (
                                                      <>
                                                          نتيجة الواجب{" "}
                                                          <span className="font-w-bold">
                                                              {percentage}%
                                                          </span>
                                                      </>
                                                  ) : (
                                                      <span className="text-rose-500">
                                                          لم يتم حضور الواجب
                                                      </span>
                                                  )}
                                              </div>
                                          ))
                                        : "لا يوجد واجبات حتى الآن"}
                                </td>
                                <td className="h-20 text-center space-y-2">
                                    {section.follow_up_data.exams.length > 0
                                        ? section.follow_up_data.exams.map((percentage, index) => (
                                              <div
                                                  className={`border-secondary-container smooth clr-text-primary pb-2 ${
                                                      index + 1 ==
                                                      section.follow_up_data.exams.length
                                                          ? ""
                                                          : "border-b"
                                                  }`}
                                              >
                                                  <span className="font-w-bold">
                                                      {placeholderArray.exams[index]}
                                                  </span>{" "}
                                                  :{" "}
                                                  {percentage !== "absent" ? (
                                                      <>
                                                          نتيجة الاختبار{" "}
                                                          <span className="font-w-bold">
                                                              {percentage}%
                                                          </span>
                                                      </>
                                                  ) : (
                                                      <span className="text-rose-500">
                                                          لم يتم حضور الاختبار
                                                      </span>
                                                  )}
                                              </div>
                                          ))
                                        : "لا يوجد اختبارات حتى الآن"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

const placeholderArray = {
    videos: [
        "الفيديو الأول",
        "الفيديو الثاني",
        "الفيديو الثالث",
        "الفيديو الرابع",
        "الفيديو الخامس",
        "الفيديو السادس",
        "الفيديو السابع",
        "الفيديو الثامن",
        "الفيديو التاسع",
        "الفيديو العاشر",
    ],
    exams: [
        "الامتحان الأول",
        "الامتحان الثاني",
        "الامتحان الثالث",
        "الامتحان الرابع",
        "الامتحان الخامس",
        "الامتحان السادس",
        "الامتحان السابع",
        "الامتحان الثامن",
        "الامتحان التاسع",
        "الامتحان العاشر",
    ],
    hms: [
        "الواجب الأول",
        "الواجب الثاني",
        "الواجب الثالث",
        "الواجب الرابع",
        "الواجب الخامس",
        "الواجب السادس",
        "الواجب السابع",
        "الواجب الثامن",
        "الواجب التاسع",
        "الواجب العاشر",
    ],
};

export default FollowUpTableCard;
