import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import {
    isMultiYear,
    isRegisterationFatherJob,
    isRegisterationGender,
} from "../../services/defaultSettings";
import { getYearPlaceHolder, years } from "../../services/yearSevices";
import { printDateTime, printFullDate, printFullDateTime } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import { Link } from "react-router-dom";
import { printIdOfOptions } from "../../utils/ar";
import auth from "../../services/authServices";

const ModeratorUserStatisticsTable = () => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "الاسم الكامل",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    element="Link"
                    to={`../user_profile/${row.id}`}
                >
                    {row.full_name}
                </Link>
            ),
            sortable: true,
            sortField: "full_name",
        },
    ];
    if (isRegisterationGender) {
        columns = [
            ...columns,
            {
                name: "النوع",
                reorder: true,
                selector: (row) =>
                    row.gender == "male"
                        ? "ذكر"
                        : row.gender == "female"
                        ? "انثى"
                        : "لم يتم الاختيار",
                sortable: true,
                sortField: "gender",
            },
        ];
    }
    columns = [
        ...columns,
        {
            name: "عدد مرات تغير الباسورد",
            reorder: true,
            selector: (row) => row.password_reset_count,
            sortable: true,
            sortField: "password_reset_count",
        },
        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    element="Link"
                    to={`../user_profile/${row.id}`}
                >
                    0{row.phone}
                </Link>
            ),
            sortable: true,
            sortField: "phone",
        },
        {
            name: "المحافظة",
            reorder: true,
            selector: (row) => printIdOfOptions(governments, row.government_id),
            sortable: true,
            sortField: "phone",
        },
        {
            name: "رقم هاتف ولي الأمر",
            reorder: true,
            selector: (row) => "0" + row.father_phone,
            sortable: true,
            sortField: "father_phone",
        },
    ];
    if (isRegisterationFatherJob) {
        columns = [
            ...columns,
            {
                name: "وظيفة ولي الأمر",
                reorder: true,
                selector: (row) => row.father_job,
                sortable: true,
                sortField: "father_job",
            },
        ];
    }
    columns = [
        ...columns,
        {
            name: "البريد الإلكتروني",
            reorder: true,
            selector: (row) => row.email,
            sortable: true,
            sortField: "email",
        },
    ];
    if (isMultiYear) {
        columns.push({
            name: "العام الدراسي",
            reorder: true,
            selector: (row) => row.year,
            format: (row) => getYearPlaceHolder(row.year),
            sortable: true,
            sortField: "year",
        });
    }
    columns = [
        ...columns,
        {
            name: "عدد الإشتراكات",
            reorder: true,
            selector: (row) => row.subscriptions_count,
            sortable: true,
            sortField: "subscriptions_count",
        },
        {
            name: "عدد الفواتير",
            reorder: true,
            selector: (row) => row.invoices_count,
            sortable: true,
            sortField: "invoices_count",
        },
        {
            name: "تاريخ اول اشتراك",
            reorder: true,
            selector: (row) =>
                row.subscriptions[0]
                    ? printFullDateTime(row.subscriptions[0].created_at)
                    : "لم يشترك حتي الأن",
            // sortable: true,
            // sortField: "created_at",
        },
        {
            name: "",
            reorder: true,
            selector: (row) =>
                row.subscriptions[row.subscriptions.length - 1]
                    ? printFullDateTime(row.subscriptions[row.subscriptions.length - 1].created_at)
                    : "",
            // format: (row) => printFullDate(row.created_at),
            // sortable: true,
            // sortField: "created_at",
        },
        {
            name: "تاريخ انشاء الحساب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const initialState = {
        full_name: "",
        phone: "",
        date_range: [],
        father_phone: "",
        email: "",
        government_id: 0,
        subscription_status: 0,
        year: 3,
        subscribed_to_courses: [],
        is_subscribed_and_operator: 0,
        not_subscribed_to_courses: [],
        is_not_subscribed_and_operator: 0,
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);

    const [governments, setGovernments] = useState([]);
    const [courses, setCourses] = useState([]);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }
    async function getCourses() {
        const token = auth.getAdminToken();
        const config = auth.getAuthConfig(token);
        const { data: response } = await http.get(
            `/api/years/${data.year}/courses/options`,
            config
        );
        setCourses(response);
    }
    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setData({
            ...data,
            subscribed_to_courses: [],
            not_subscribed_to_courses: [],
        });
        getCourses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.year]);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="full_name"
                        placeholder="اسم الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="date_range"
                        type="date_range"
                        placeholder="اختر موعد انشاء الحساب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    <InputField
                        id="father_phone"
                        placeholder="رقم هاتف ولي الأمر"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="email"
                        placeholder="البريد الإلكتروني"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="government_id"
                        placeholder="المحافظة"
                        type="select"
                        options={[
                            {
                                value: 0,
                                text: "الكل",
                            },
                            ...governments,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    <div className="flex-center-both px-10 lg:col-span-2">
                        <div className="bg-secondary-container rounded-md h-1 w-full smooth"></div>
                    </div>
                    {isMultiYear ? (
                        <InputField
                            id="year"
                            placeholder="العام الدراسي"
                            type="select"
                            options={[
                                {
                                    value: 0,
                                    text: "الكل",
                                },
                                ...years.filter((value, index) => index < 3),
                            ]}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className="lg:col-span-2"
                        />
                    ) : (
                        ""
                    )}
                    <InputField
                        id="subscribed_to_courses"
                        placeholder="كورسات مشتركين بها"
                        type="select"
                        multiple={true}
                        options={courses}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="is_subscribed_and_operator"
                        placeholder="يكون مشترك فيهم كلهم"
                        type="switch"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="not_subscribed_to_courses"
                        placeholder="كورسات غير مشتركين بها بها"
                        type="select"
                        multiple={true}
                        options={courses}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="is_not_subscribed_and_operator"
                        placeholder="يكون مش مشترك في ولا واحد فيهم"
                        type="switch"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/moderator_users/user_statistics/paginate`}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default ModeratorUserStatisticsTable;
