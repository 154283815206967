import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { adminPath } from "../../../services/defaultSettings";
import CenterIcon from "../../../components/ui/CenterIcon";

const MainNotification = () => {
    const [isHidden, setIsHidden] = useState(true);

    const [className, setClassName] = useState("");

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location]);

    return (
        <>
            <div
                className={`absolute w-full top-[5rem] right-0 left-0 px-2 py-2 ${className} ${
                    isHidden ? "!hidden" : ""
                }`}
            >
                <div className="relative rounded-md bg-rose-800 bg-opacity-90 dark:bg-rose-500 dark:bg-opacity-80 smooth text-white p-5 text-center">
                    <div className="flex-center-both flex-col space-y-2">
                        <div className="font-w-bold font-h2 underline">خلي بالك</div>
                        <div className="">
                            بسبب تفعيل التوقيت الشتوي
                            <br />
                            فوري هتكون واقفة من الساعة{" "}
                            <span className="underlined font-w-bold text-yellow-300">
                                11 مساءًا
                            </span>{" "}
                            النهاردة الخميس 31 اكتوبر و هتقعد{" "}
                            <span className="underlined  font-w-bold text-yellow-300">
                                خمس ساعات متوقفة عن الدفع
                            </span>
                            <br />
                            مش مسموح تطلع فاتورة او تدفع في الوقت ده !!
                        </div>
                    </div>
                    <button
                        className="absolute left-2 top-2 pt-0.5 flex-center-both rounded-md  bg-rose-800 font-h1 hover-shadow smooth"
                        onClick={() => setIsHidden(true)}
                    >
                        <CenterIcon icon="uil:x" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default MainNotification;
