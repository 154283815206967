import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import InputIcon from "../../components/form/elements/InputIcon";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import AdminContainer from "../../components/ui/AdminContainer";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
} from "../../services/formServices";
import AdminForm from "../../components/ui/AdminForm";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { printUnit } from "../../utils/ar";
import Button from "../../components/ui/Button";
import { years } from "../../services/yearSevices";

const initialState = {
    moderator_id: "",
    quantity: 0,
    start_from: "older",
    year: 3,
    course_id: 0,
    users_from: 0,
};

const DivideModeratorUsers = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [isAdminUsersDataLoading, setIsAdminUsersDataLoading] = useState(true);

    const [inputFields, setInputFields] = useState([]);
    const [selectorInputFields, setSelectorInputFields] = useState([]);

    const [admins, setAdmins] = useState([]);
    const [courses, setCourses] = useState([]);
    const [adminsData, setAdminData] = useState(null);
    const [selectorsData, setSelectorData] = useState(null);
    const [isSelectorDataLoading, setIsSelectorDataLoading] = useState(true);
    const [isNotSelected, setIsNotSelected] = useState(true);

    const getSelectorData = async () => {
        setIsSelectorDataLoading(true);
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/users/get_moderator_users_info`, {
            ...config,
            params: data,
        });
        setSelectorData(response);
        setIsSelectorDataLoading(false);
    };

    const getAdminsData = async () => {
        setIsAdminUsersDataLoading(true);
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/users/get_moderator_users_info`, config);
        setAdminData(response);
        setIsAdminUsersDataLoading(false);
    };
    const getAdmins = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(`/api/admins`, config);

        let adminOptions = response.map((user) => {
            return { value: user.id, text: user.full_name + ` (@${user.email})` };
        });
        setAdmins(adminOptions);
    };
    const getCourses = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/years/${data.year}/courses/options`,
            config
        );
        setCourses(response);
    };

    useEffect(() => {
        getAdminsData();
        getAdmins();
    }, []);
    useEffect(() => {
        if (!data.users_from) {
            setIsNotSelected(true);
        } else if (data.users_from === "all_users") {
            setIsNotSelected(false);
            getSelectorData();
        } else if (data.users_from === "year_users") {
            if (!data.year) {
                setIsNotSelected(true);
            } else {
                setIsNotSelected(false);
                getSelectorData();
            }
        } else if (data.users_from === "subscribed_users") {
            if (!data.course_id) {
                setIsNotSelected(true);
            } else {
                setIsNotSelected(false);
                getSelectorData();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.users_from, data.year, data.course_id]);
    useEffect(() => {
        if (data.users_from === "subscribed_users") {
            getCourses();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.year, data.users_from]);

    useEffect(() => {
        let selectorInputFields = [];

        selectorInputFields = [
            ...selectorInputFields,
            {
                id: "users_from",
                placeholder: "اختيار الطلبة من : ",
                type: "select",
                options: [
                    { value: "all_users", text: "من كل طلبة المنصة" },
                    { value: "year_users", text: "من طلبة سنة دراسية معينة" },
                    { value: "subscribed_users", text: "من طلبة مشتركة بكورس معين" },
                ],
            },
        ];
        if (data.users_from === "year_users") {
            selectorInputFields = [
                ...selectorInputFields,
                {
                    id: "year",
                    placeholder: "اختر السنة الدراسية",
                    type: "select",
                    options: years.filter((value, index) => index < 3),
                },
            ];
        } else if (data.users_from === "subscribed_users") {
            selectorInputFields = [
                ...selectorInputFields,
                {
                    id: "year",
                    placeholder: "اختر السنة الدراسية",
                    type: "select",
                    options: years.filter((value, index) => index < 3),
                },

                {
                    id: "course_id",
                    placeholder: "اختر الكورس",
                    options: courses,
                    type: "select",
                },
            ];
        }

        let fields = [];

        fields = [
            ...fields,
            {
                id: "moderator_id",
                placeholder: "اختر الادمن",
                type: "select",
                options: admins,
            },
        ];
        fields = [
            ...fields,
            {
                id: "quantity",
                placeholder: "عدد الطلبة المضافة",
                type: "number",
                icon: <InputIcon icon="la:users-cog" />,
            },
        ];

        fields = [
            ...fields,
            {
                id: "start_from",
                placeholder: "اختر نوع الطلبة",
                type: "select",
                options: [
                    { value: "older", text: "من الطلبة الأقدم" },
                    { value: "newer", text: "من الطلبة الأحدث" },
                    { value: "random", text: "عشوائي" },
                ],
            },
        ];
        setInputFields(fields);
        setSelectorInputFields(selectorInputFields);
    }, [admins, data.users_from, courses]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            divideUsers();
        });
    };
    const divideUsers = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            const toSendData = { ...data };
            const { data: response } = await http.post(
                "/api/users/divide_moderator_users",
                toSendData,
                config
            );

            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: response.message,
                callback: () => {
                    getAdminsData();
                    getSelectorData();
                },
            });
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <div className="space-y-10">
            <AdminContainer>
                <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="تفعيل">
                    <div className="rounded-md w-full border flex flex-col p-2 h-full ">
                        <div className="text-center font-w-bold font-h3 pb-4">
                            {" "}
                            -- كل الطلبة : --
                        </div>
                        <AdminUsersInfo
                            isAdminUsersDataLoading={isAdminUsersDataLoading}
                            isModerators={true}
                            adminsData={adminsData}
                            isReset={true}
                            callBack={getAdminsData}
                        />
                    </div>
                    {selectorInputFields.map((input, key) => (
                        <InputField
                            key={key}
                            onChange={handleChange}
                            data={data}
                            setData={setData}
                            errors={errors}
                            {...input}
                        />
                    ))}
                    <div className="rounded-md w-full border flex flex-col p-2 h-full ">
                        <div className="text-center font-w-bold font-h3 pb-4">
                            {" "}
                            -- الطلبة اللي في الفلتر : --
                        </div>
                        {!isNotSelected ? (
                            <AdminUsersInfo
                                isAdminUsersDataLoading={isSelectorDataLoading}
                                adminsData={selectorsData}
                                isModerators={true}
                                isReset={false}
                            />
                        ) : (
                            <div className="flex-center-both flex-row text-center space-x-2 space-x-reverse">
                                <div>
                                    اختار الفلتر الأول عشان تشوف توزيعة الطلبة اللي في الفلتر !
                                </div>
                            </div>
                        )}
                    </div>

                    {inputFields.map((input, key) => (
                        <InputField
                            key={key}
                            onChange={handleChange}
                            data={data}
                            setData={setData}
                            errors={errors}
                            {...input}
                        />
                    ))}
                </AdminForm>
            </AdminContainer>
        </div>
    );
};

export default DivideModeratorUsers;

const CounterRow = ({
    title,
    count,
    isResetButton = true,
    resetButtonText = "اعادة تعيين",
    color = "blue",
    id = false,
    callback = () => null,
}) => {
    let unitClassName = "bg-blue-600";
    switch (color) {
        case "yellow":
            unitClassName = "bg-yellow-600";
            break;
        case "green":
            unitClassName = "bg-green-600";
            break;
        case "rose":
            unitClassName = "bg-rose-600";
            break;

        default:
            break;
    }

    const [isLoading, setIsLoading] = useState(false);

    const resetAdminUsers = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        try {
            const { data: response } = await http.post(
                `/api/users/reset_moderator_id`,
                { moderator_id: id },
                config
            );
            // setAdminData(response);
            modal.message({
                title: "عملية ناجحة",
                text: `تمت اعادة تعيين : ${printUnit(response.users_reseted_count, "طالب")}`,
                icon: "success",
                callback: () => {
                    callback();
                },
            });
            setIsLoading(false);
        } catch (e) {}
    };

    return (
        <div className="flex flex-row items-center justify-between w-full">
            <div className="font-w-bold underline">{title}</div>
            <div className="flex flex-row space-x-2 space-x-reverse">
                {isResetButton && count > 0 ? (
                    <div>
                        <Button
                            isLoading={isLoading}
                            className="py- px-2 font-smaller"
                            color="rose"
                            onClick={resetAdminUsers}
                        >
                            {resetButtonText}
                        </Button>
                    </div>
                ) : (
                    ""
                )}
                <div className={`px-3 rounded-full text-center  text-white ${unitClassName}`}>
                    {printUnit(count, "طالب")}
                </div>
            </div>
        </div>
    );
};

const AdminUsersInfo = ({
    isAdminUsersDataLoading = true,
    adminsData,
    isModerators = false,
    isReset = true,
    callBack,
}) => {
    return (
        <>
            {isAdminUsersDataLoading ? (
                <div className="flex-center-both">
                    <span className="flex-center-both font-h1 text-blue-500">
                        <LoadingIcon />
                    </span>
                    <span>يتم الآن تحميل بيانات المستخدمين</span>
                </div>
            ) : (
                <div className="font-small space-y-3">
                    {/* <div className="flex-center-both space-x-3 space-x-reverse">
                                <div className="font-w-bold underline">
                                    عدد المستخدمين بدون ادمن :
                                </div>
                                <div className="px-3 rounded-full bg-yellow-600 text-white">
                                    {printUnit(adminsData["not_attached_users_count"], "طالب")}
                                </div>
                            </div> */}
                    <CounterRow
                        title="اجمالي عدد الطلبة"
                        count={adminsData["all_users_count"]}
                        color="green"
                        isResetButton={false}
                    />
                    {isModerators ? (
                        ""
                    ) : (
                        <CounterRow
                            title="عدد الطلبة اللي اشتركت آخر ٣٠ يوم"
                            count={adminsData["active_users_count"]}
                            color="green"
                            isResetButton={false}
                        />
                    )}

                    <div className="w-full rounded-full h-1 bg-secondary-container"></div>

                    <CounterRow
                        title="عدد المستخدمين بدون ادمن :"
                        count={adminsData["not_attached_users_count"]}
                        color="yellow"
                        isResetButton={false}
                    />
                    <CounterRow
                        title="اجمالي عدد الطلبة الموزعة"
                        count={adminsData["users_divided_count"]}
                        color="green"
                        resetButtonText="اعادة تعيين جميع الطلبة"
                        id="all_users"
                        callback={callBack}
                        isResetButton={isReset}
                    />
                    {adminsData["users_with_invalid_admin_count"] > 0 ? (
                        <CounterRow
                            title="عدد الطلبة مع ادمن تم حذفه"
                            count={adminsData["users_with_invalid_admin_count"]}
                            color="rose"
                            id="invalid_admin"
                            callback={callBack}
                            isResetButton={isReset}
                        />
                    ) : (
                        ""
                    )}

                    <div className="w-full rounded-full h-1 bg-secondary-container"></div>
                    {adminsData.admins.map((admin) => (
                        <React.Fragment key={admin.id}>
                            <CounterRow
                                title={
                                    <>عدد المستخدين مع {admin.full_name + ` (@${admin.email})`} :</>
                                }
                                count={
                                    isModerators
                                        ? admin["moderator_users_count"]
                                        : admin["users_count"]
                                }
                                color="rose"
                                id={admin.id}
                                callback={callBack}
                                isResetButton={isReset}
                            />
                        </React.Fragment>
                    ))}
                </div>
            )}
        </>
    );
};
