import React, { useEffect, useState } from "react";
import Heading from "../../components/ui/Heading";
import Container from "../../components/ui/Container";
import http from "../../services/httpServices";
import CourseFollowUpSection from "../../components/ui/CourseFollowUpSection";
import ProgressCircle from "../../components/ui/ProgressCircle";
import CenterIcon from "../../components/ui/CenterIcon";
import Form from "../../components/form/elements/Form";
import InputField from "../../components/form/elements/InputField";
import { handleFormErrors, handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";
import QuestionSvg from "../../assets/parent-dashboard-assets/QuestionSvg.svg";
import EnglandEarth from "../../assets/parent-dashboard-assets/EnglandDecore.png";
import { ReactComponent as SideNavCollabseIcon } from "../../assets/parent-dashboard-assets/Arrows.svg";
const ParentDashboard = () => {
    const [followUpData, setFollowUpData] = useState({ courses: [] });
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [data, setData] = useState({ phone: "", father_phone: "" });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const getParentDashboardData = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            await http.get("/sanctum/csrf-cookie");
            const result = await http.post("api/parent_dashboard", {
                phone: parseInt(data["phone"]),
                father_phone: parseInt(data["father_phone"]),
            });
            setFollowUpData(result.data);
            setSelectedCourse([]);
        } catch (error) {
            handleFormErrors(error, setIsLoading, setErrors);
        }
    };

    const handleCourseSelection = (course) => {
        setSelectedCourse(course);
    };

    return (
        <Container className="">
            <div className="flex flex-col items-center justify-center h-fit gap-10">
                <HeroSection />
            </div>
            {!followUpData.full_name ? (
                <Form className=" w-full md:w-1/2 mx-auto  gap-5">
                    <div className="space-y-5 p-8 rounded-md shadow-xl">
                        <InputField
                            placeholder="رقم هاتف نجلك"
                            id="phone"
                            onChange={handleInputChange}
                            data={data}
                            errors={errors}
                            setData={setData}
                        />
                        <InputField
                            placeholder="رقم هاتفك"
                            id="father_phone"
                            onChange={handleInputChange}
                            data={data}
                            errors={errors}
                            setData={setData}
                        />
                        <Button
                            color="violet"
                            className="w-full"
                            onClick={getParentDashboardData}
                            isLoading={isLoading}
                        >
                            ابدأ المتابعة
                        </Button>
                    </div>
                </Form>
            ) : (
                ""
            )}
            <div className="flex gap-5 flex-col md:flex-row">
                <div className="w-full md:w-fit">
                    {followUpData.full_name ? (
                        <StatisticsSection
                            followUpData={followUpData}
                            onCourseSelect={handleCourseSelection}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="w-full">
                    {selectedCourse && followUpData.full_name ? (
                        <CourseFollowUpSection course={selectedCourse} />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </Container>
    );
};

const HeroSection = () => {
    const [activeWordIndex, setActiveWordIndex] = useState(0);
    const [loopCount, setLoopCount] = useState(0);

    useEffect(() => {
        const totalLoops = 10;
        const totalWords = 3;

        if (loopCount < totalLoops) {
            const interval = setInterval(() => {
                setActiveWordIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % totalWords;
                    if (newIndex === totalWords - 1) {
                        setLoopCount((prevLoop) => {
                            const newLoopCount = prevLoop + 1;
                            return newLoopCount;
                        });
                    }
                    return newIndex;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [activeWordIndex, loopCount]);
    return (
        <div className="py-10 md:py-20">
            <div className="container mx-auto flex flex-col items-center justify-center text-center px-4 md:px-0">
                <h1 className="text-3xl font-expo md:text-5xl font-bold mb-4 flex-center-both gap-3 ">
                    لوحة تحكم ولي الأمر
                    <img src={QuestionSvg} alt="QuestionSvg" className="w-14" />
                </h1>

                <div className="flex justify-center items-center gap-6">
                    <span role="img">
                        <img src={EnglandEarth} alt="EnglandEarth" className="w-10 opacity-40" />
                    </span>
                    <h2 className="text-2xl font-caveat md:text-5xl fade-in-out lg:text-8xl font-bold text-violet-200 dark:text-violet-800 smooth">
                        <span
                            className={`${
                                activeWordIndex === 0 ? "text-stroke" : "text-stroke-none"
                            } `}
                        >
                            Monitor.
                        </span>{" "}
                        <span
                            className={`${
                                activeWordIndex === 1 ? "text-stroke" : "text-stroke-none"
                            } `}
                        >
                            Motivate.
                        </span>{" "}
                        <span
                            className={`${
                                activeWordIndex === 2 ? "text-stroke" : "text-stroke-none"
                            } `}
                        >
                            Fullmark
                        </span>
                    </h2>
                    <span role="img">
                        <img src={EnglandEarth} alt="EnglandEarth" className="w-10 opacity-40" />
                    </span>
                </div>

                <p className="mt-6 text-lg font-expo md:text-xl text-gray-600 max-w-2xl">
                    تابع كل ما يخص نجلك لضمان أعلى درجة ومتابعة مستواه الدراسي في منصة fullmark ...
                    اهتماماتك اهم جزء في رحلته
                </p>
            </div>
        </div>
    );
};

const StatisticsSection = ({ followUpData, onCourseSelect = () => null }) => {
    // console.log("followUpData", JSON.stringify(followUpData));
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <section className={`space-y-10 h-full `}>
            {followUpData.courses.length > 0 ? (
                <>
                    <nav
                        className={`flex flex-row md:flex-col h-full bg-[#F8F8F8] flex-wrap md:flex-nowrap space-x-6 md:space-x-0 dark:bg-slate-900 border border-black rounded-xl p-5 space-y-4 smooth ${
                            isCollapsed ? "w-full md:w-16" : "w-full h-full "
                        }`}
                    >
                        <div className="flex justify-center w-full mb-4">
                            <button
                                onClick={toggleCollapse}
                                className="p-2 rounded-full bg-gray-200 dark:bg-slate-800 smooth hover:bg-gray-300 dark:hover:bg-slate-700"
                            >
                                <SideNavCollabseIcon
                                    className={`w-10 h-auto ${
                                        isCollapsed
                                            ? "rotate-90 md:rotate-180"
                                            : "-rotate-90 md:rotate-0"
                                    } smooth`}
                                />
                            </button>
                        </div>
                        {followUpData.courses.map((course, index) => (
                            <Button
                                key={index}
                                onClick={() => onCourseSelect(course)}
                                state={{ course }}
                                className={`block p-3 text-lg flex-center-both flex-col text-center clr-text-primary font-semibold border-none bg-[#DDCFFC] dark:bg-violet-700 smooth hover:bg-violet-300 rounded-lg transition-colors duration-300 ease-in-out ${
                                    isCollapsed ? "text-center" : "text-left"
                                }`}
                            >
                                <span className=""> - {index} - </span>
                                {!isCollapsed && course.name}
                            </Button>
                        ))}
                    </nav>
                </>
            ) : (
                ""
            )}
        </section>
    );
};

const OverviewStatistics = () => {
    return (
        <div>
            <div className="font-w-bold text-center font-h1 pt-5 pb-10 flex-center-both space-x-3 space-x-reverse">
                <CenterIcon icon="arcticons:questionnaire-star" className="text-violet-500" />
                <span>
                    احصائيات إجمالية لنجلك في{" "}
                    <span className="text-violet-500">الكورسات اللي هو مشترك فيها</span>{" "}
                </span>
                <CenterIcon icon="arcticons:questionnaire-star" className="text-violet-500" />
            </div>
            <div className="w-full flex items-center justify-evenly">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 items-start">
                    <ProgressCircle
                        color="violet"
                        unit="فيديو"
                        title="عدد الفيديوهات نجلك شافها"
                        value={5}
                        max={10}
                        textsIndex={3}
                    />
                    <ProgressCircle
                        color="cyan"
                        unit="امتحان و واجب"
                        title="عدد الامتحانات و الواجبات نجلك خلصها"
                        value={5}
                        max={10}
                        textsIndex={2}
                    />
                    <ProgressCircle
                        color="purple"
                        unit={false}
                        title="متوسط نتائج نجلك"
                        value={5}
                        max={10}
                        textsIndex={1}
                    />
                </div>
            </div>
        </div>
    );
};

export default ParentDashboard;
