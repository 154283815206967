import React, { useEffect, useState } from "react";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import modal from "../../services/modalServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import AdminForm from "../../components/ui/AdminForm";
import { toast } from "react-toastify";

const AddAdminToAdminNotesForm = ({
    user = { id: 0 },
    afterSuccess = () => null,
    onTable = false,
    editableData = [],
    setEditableData = () => null,
    setIsToEditData = () => null,
    isSubRow = false,
}) => {
    const [data, setData] = useState({
        tags: [],
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const inputFields = [
        {
            id: "note",
            type: "text",
            placeholder: "اكتب ملاحظتك",
        },
    ];
    const handleSubmit = (e) => {
        // return;
        setIsLoading(true);

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            modal.message({
                title: "هل انت متأكد",
                text: ``,
                icon: "info",
                // button: "تأكيد",
                buttons: {
                    confirm: "تأكيد",
                    cancel: "إلغاء",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        handleAdminToAdminNoteChange();
                    } else {
                        setIsLoading(false);
                    }
                },
            });
        });
    };
    const handleAdminToAdminNoteChange = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            const { data: response } = await http.post(
                `/api/users/${user.id}/admin_to_admin_user_note`,
                data,
                config
            );

            toast.success(`عملية ناجحة`, { theme: "colored" });

            setData({
                tags: [],
            });
            setIsLoading(false);
            afterSuccess(
                user,
                response.note,
                editableData,
                setEditableData,
                setIsToEditData,
                isSubRow
            );
        } catch ({ response }) {
            // console.log(response);
            handleFormErrors(response, setIsLoading, setErrors, false);
        }
    };
    return (
        <>
            <AdminForm
                className={`${
                    onTable
                        ? "bg-third-container space-y-3 py-2 px-1 rounded-md clr-text-primary smooth border border-cyan-500 border-opacity-20"
                        : ""
                }`}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="اضافة ملاحظات للأدمن"
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </>
    );
};

export default AddAdminToAdminNotesForm;
