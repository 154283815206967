import React from "react";
import { printShortDateTime } from "../../utils/time";

const AdminToAdminNotesTableCell = ({ user }) => {
    return (
        <div>
            {user.admin_to_admin_user_notes && user.admin_to_admin_user_notes.length > 0 ? (
                user.admin_to_admin_user_notes.map((note, index) => {
                    // return note.name + " ";

                    if (index > 2) return;
                    return (
                        <div
                            key={note.id}
                            className="bg-slate-400 dark:bg-slate-700 smooth clr-text-primary mb-2 rounded-md py-4"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {note.name}
                            <br />
                            <br />
                            {printShortDateTime(note.created_at)}
                        </div>
                    );
                })
            ) : (
                <div className="text-gray-500">لا يوجد ملاحظات</div>
            )}
        </div>
    );
};

export default AdminToAdminNotesTableCell;
